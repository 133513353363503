import React, { useState } from 'react'
import { Link } from 'gatsby'
import { isEmpty } from 'lodash'
import { useFormik } from 'formik'
import { navigate } from 'gatsby-link'
import ReactMarkdown from 'react-markdown'
import { Button, Form, Message } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import Pvolve from '@pvolve/sdk'

import { Banner, Icon } from 'src/components/shared'
import { Asset } from 'contentful'
import { getErrorMessage, hasAllValues, validateEmail } from 'src/utils/form-utils'

import Actions from 'src/state/root-actions'
import PvolveSelectors from '@pvolve/sdk/src/app/selectors'

import * as Styles from 'src/styles/partners-activation-form.module.scss'

interface Logo extends Asset {
    src?: string
    alt?: string
}

interface OptumMember {
    member_id: string
    is_eligible: boolean
    partner_code: string
}
interface ActivationFormProps {
    hint?: string
    product?: string
    partnerLogo?: Logo
    instructionsLong?: string
    sadPathMessageTxt?: string
    partner: string
    shopifyUrl?: string
}

const ActivationForm = ({ hint, partner, partnerLogo, instructionsLong, sadPathMessageTxt }: ActivationFormProps) => {
    const dispatch = useDispatch()

    const shopifyUrl = useSelector(PvolveSelectors.config.shopifyUrl)
    const [sadPathMessage, setSadPathMessage] = useState<string>()

    const formik = useFormik({
        initialValues: {
            email: '',
            confirmationCode: '',
        },
        validateOnChange: false,
        validateOnBlur: true,
        validate: values => {
            interface Errors {
                email?: string
                confirmationCode?: String
            }

            const errors = {} as Errors
            const confirmationCodeRegex = new RegExp(/^([ABS])([A-Za-z0-9]){9}$/)

            if (values.email && validateEmail(values).email) {
                errors.email = 'Please enter a valid email'
            }

            if (values.confirmationCode && !confirmationCodeRegex.test(values.confirmationCode)) {
                errors.confirmationCode = 'This code is incorrect'
            }

            return errors
        },
        onSubmit: async values => {
            const onSuccess = ({ is_eligible }: { is_eligible: boolean }) => {
                if (is_eligible) {
                    return navigate('/optum/success')
                }

                setSadPathMessage(sadPathMessageTxt)
            }

            setSadPathMessage(undefined)

            try {
                const user = await Pvolve.api.account.findUserByEmail(values.email)

                if (user?.status === 'CONFIRMED') {
                    return setSadPathMessage(
                        'We noticed that you have an existing P.volve account. Please contact customer service at help@pvolve.com and we will be happy to help you enroll.'
                    )
                }
            } catch (err) {
                const errorCode = err?.response?.data?.code
                const errorMessage = err?.response?.data?.message
                const errorStatus = err?.response?.status

                if (
                    (errorCode === 0 && errorMessage?.match(/Unable to find User/)) ||
                    (errorStatus === 404 && errorMessage?.match(/Unable to find user with id/))
                ) {
                    dispatch(
                        Actions.subscriptions.activation.verify.trigger({
                            provider: 'optum',
                            member_id: values.confirmationCode,
                            onSuccess,
                        })
                    )
                    dispatch(
                        Actions.account.setEmail.trigger({
                            email: values.email,
                        })
                    )
                }
            }
        },
    })

    const handleConfirmationCodeChange = e => {
        e.target.value = e.target.value.toUpperCase()
        formik.handleChange(e)
    }

    const errors = formik.errors

    return (
        <div className="text-align--center padding--small">
            {partnerLogo && (
                <div className={Styles.logos}>
                    <Icon name="pv-logo-name" size={35} />
                    <Icon name="pv-plus" size={15} className={Styles.plus} />
                    <img src={partnerLogo.src} alt={partnerLogo.alt} />
                </div>
            )}
            <h5>
                We're currently upgrading our systems to serve you better. Please email help@pvolve.com to redeem your
                coupon. We appreciate your patience and understanding.
            </h5>
        </div>
    )

    /* DO NOT REMOVE */
    /* TEMPORARY HOLD: The form functionality below has been temporarily disabled */
    // return (
    //     <div className={Styles.activationForm}>
    //         {sadPathMessage && (
    //             <Banner withIcon type="WARNING" className={Styles.banner}>
    //                 {sadPathMessage}
    //             </Banner>
    //         )}
    //         {partnerLogo && (
    //             <div className={Styles.logos}>
    //                 <Icon name="pv-logo-name" size={35} />
    //                 <Icon name="pv-plus" size={15} className={Styles.plus} />
    //                 <img src={partnerLogo.src} alt={partnerLogo.alt} />
    //             </div>
    //         )}
    //         {instructionsLong ? (
    //             <ReactMarkdown>{instructionsLong}</ReactMarkdown>
    //         ) : (
    //             <p className="p1"> Please enter your activation code below.</p>
    //         )}
    //         <Form>
    //             <Form.Field>
    //                 <Form.Input
    //                     fluid
    //                     id="email"
    //                     type="text"
    //                     name="email"
    //                     label={{
    //                         children: 'Email',
    //                         htmlFor: 'email',
    //                     }}
    //                     placeholder="email"
    //                     onBlur={formik.handleBlur}
    //                     value={formik.values.email}
    //                     onChange={formik.handleChange('email')}
    //                     error={errors.email ? getErrorMessage(errors.email) : false}
    //                 />
    //                 <div className={Styles.infoText}>
    //                     {hint && (
    //                         <>
    //                             <Icon size={15} name="pv-info" className={Styles.infoCircle} />
    //                             <p className="p3">{hint}</p>
    //                         </>
    //                     )}
    //                 </div>
    //             </Form.Field>
    //             <Form.Field>
    //                 <Form.Input
    //                     fluid
    //                     type="text"
    //                     maxLength={10}
    //                     id="confirmationCode"
    //                     name="confirmationCode"
    //                     placeholder="A1232456789"
    //                     onBlur={formik.handleBlur}
    //                     onChange={handleConfirmationCodeChange}
    //                     value={formik.values.confirmationCode}
    //                     className={Styles.confirmationCodeInput}
    //                     label={{
    //                         children:
    //                             partner === 'onepass-commercial'
    //                                 ? 'member code'
    //                                 : 'confirmation code',
    //                         htmlFor: 'confirmationCode',
    //                     }}
    //                     error={
    //                         errors.confirmationCode
    //                             ? getErrorMessage(errors.confirmationCode)
    //                             : false
    //                     }
    //                 />
    //                 <div className={Styles.infoText}>
    //                     {hint && (
    //                         <>
    //                             <Icon size={15} name="pv-info" className={Styles.infoCircle} />
    //                             <p className="p3">{hint}</p>
    //                         </>
    //                     )}
    //                 </div>
    //             </Form.Field>
    //             <p className={Styles.terms}>
    //                 By redeeming your membership, you agree to P.volve’s{' '}
    //                 <a target="_blank" href={`${shopifyUrl}/pages/terms-of-service`}>
    //                     terms and conditions.
    //                 </a>
    //             </p>
    //             <Button
    //                 fluid
    //                 primary
    //                 size="big"
    //                 disabled={!hasAllValues(formik.values) || !isEmpty(errors)}
    //                 onClick={formik.handleSubmit}
    //             >
    //                 Redeem Now
    //             </Button>
    //         </Form>
    //
    //         <div className={Styles.trialist}>
    //             <Link to="/continue" className="upper">
    //                 Already activated your account? Log in
    //             </Link>
    //         </div>
    //
    //         {/* Temporarily hiding this until updated copy comes in [UCX-111] */}
    //         {false && (
    //             <div className={Styles.trialist}>
    //                 <p className="p3 margin-bottom--0 text-align--center">
    //                     Not a RenewActive member?
    //                 </p>
    //                 <Link to="/start-trial/14-day/">Start a 14 day free trial.</Link>
    //             </div>
    //         )}
    //     </div>
    // );
}

export default ActivationForm
